import WatchflagDisabled from "../assets/img/icon.dashboard.watchflag.disabled.svg";
import WatchflagEnabled from "../assets/img/icon.dashboard.watchflag.enabled.svg";
import PausedUnitIcon from "../assets/img/icon.dashboard.paused.svg";
import PausedUnitWhiteIcon from "../assets/img/icon.dashboard.paused.white.svg";
import ActivityDashboard from "../assets/img/icon.dashboard.activity.white.svg";
import BathroomDashboard from "../assets/img/icon.dashboard.bath.svg";
import SleepDashboard from "../assets/img/icon.dashboard.sleep.svg";
import TemperatureDashboard from "../assets/img/icon.dashboard.temperature.svg";
import KitchenDashboard from "../assets/img/icon.dashboard.kitchen.svg";
import BathroomAnomaly from "../assets/img/icon.notification.bath.white.svg";
import HeatIndexWarning from "../assets/img/icon.notification.temperature.white.svg";
import HelpButton from "../assets/img/icon.notifications.exclamationpoint.white.svg";
import HelpButtonOutlined from "../assets/img/icon.notifications.exclamationpoint.svg";
import ActivityEvent from "../assets/img/icon.notification.activity.white.svg";
import NightEvent from "../assets/img/icon.notification.sleep.white.svg";
import GearIcon from "../assets/img/icon.tab.settings.svg";
import DoorExit from "../assets/img/icon.notifications.door_exit.svg";
import NoGateway from "../assets/img/icon.dashboard.no_gateway.svg";
import DoorOpen from "../assets/img/icon.notification.exterior_door_open.white.svg";
import HandOff from "../assets/img/icon.notification.handoff.svg";

import PhonePager from "../assets/img/icon.contacts.phone.pager.svg";
import PhoneHome from "../assets/img/icon.contacts.phone.home.svg";
import Email from "../assets/img/icon.contacts.email.svg";
import PhoneMobile from "../assets/img/icon.contacts.phone.mobile.svg";
import PhoneWork from "../assets/img/icon.contacts.phone.work.svg";
import AddressBook from "../assets/img/icon.contacts.address_book.svg";
import PhoneMain from "../assets/img/icon.contacts.phone.main.svg";

import Resolved from "../assets/img/icon.notifications.resolved.no_shadow.svg";
import Assigned from "../assets/img/icon.notifications.assigned_arrow.no_shadow.svg";

import MinimizeChart from "../assets/img/icon.analytics.minimize.svg";
import MaximizeChart from "../assets/img/icon.analytics.maximize.svg";
import Log from "../assets/img/icon.log.svg";

import SortArrowDown from "../assets/img/icon.sort_arrow.svg";
import Filter from "../assets/img/icon.filter.svg";
import CheckNone from "../assets/img/icon.checkall.none.svg";
import CheckMixed from "../assets/img/icon.checkall.mixed.svg";
import CheckAll from "../assets/img/icon.checkall.all.svg";
import Selected from "../assets/img/icon.checkbox.selected.svg";
import Unselected from "../assets/img/icon.checkbox.unselected.svg";

import HandOffSettings from "../assets/img/icon.settings.hand_off.svg";
import UnitsResidents from "../assets/img/icon.settings.units_residents.svg";
import CareUser from "../assets/img/icon.settings.care_user.svg";
import CommunityUsers from "../assets/img/icon.settings.community_users.svg";
import NotificationSettings from "../assets/img/icon.settings.notification_settings.svg";
import NotificationGroups from "../assets/img/icon.settings.notification_groups.svg";
import CommunitySettings from "../assets/img/icon.settings.community.svg";
import OrganizationSettings from "../assets/img/icon.settings.organization.svg";

import CloseIcon from "../assets/img/icon.close_x.svg";

import ReportIcon from "../assets/img/icon.analytics_report_24.svg";
import PrintReportIcon from "../assets/img/icon.analytics.print_report_24.svg";
import ReportsIconWhite from "../assets/img/icon.analytics_report_white.svg";

import PanRight from "../assets/img/icon.analytics.pan_right_outline.svg";
import PanLeft from "../assets/img/icon.analytics.pan_left_outline.svg";
import AnalyticsShortcut from "../assets/img/icon.notification_tray.analytics_shortcut.svg";
import ZoomOut from "../assets/img/icon.analytics.zoom_out.svg";
import ZoomIn from "../assets/img/icon.analytics.zoom_in.svg";
import Calendar from "../assets/img/icon.analytics.calendar.svg";
import CalendarCheck from "../assets/img/icon.check_button_3px.svg";
import CalendarClose from "../assets/img/icon.close_button_3px.svg";

import DashboardIcon from "../assets/img/icon.navigation.dashboard.svg";
import NotificationsIcon from "../assets/img/icon.navigation.notifications.svg";
import AnalyticsIcon from "../assets/img/icon.navigation.analytics.svg";
import DevicesIcon from "../assets/img/icon.navigation.devices.svg";
import SettingsIcon from "../assets/img/icon.navigation.settings.svg";
import ShopIcon from "../assets/img/icon.navigation.shop.svg";

import MotionDevice from "../assets/img/devices.motion_sensor.front.svg";
import ContactDevice from "../assets/img/devices.contact_sensor.front.svg";
import HelpButtonDevice from "../assets/img/icon.devices.help_button.square.solid.svg";
import GatewayDevice from "../assets/img/devices.gateway_top_view.svg";
import OutletDevice from "../assets/img/icon.devices.outlet.square.solid.svg";
import BulbDevice from "../assets/img/icon.devices.bulb.square.solid.svg";
import RadarDevice from "../assets/img/icon.devices.vayyar.square.solid.svg";
import BatteryLow from "../assets/img/icon.devices.battery_low.overlay.svg";
import Refresh from "../assets/img/icon.refresh.svg";
import SamjinMotionDevice from "../assets/img/icon.devices.square.samjin_motion.solid.svg";
import SamjinContactDevice from "../assets/img/icon.devices.square.samjin_multi.solid.svg";
import EriaMotionDevice from "../assets/img/icon.devices.square.eria_vms_adurolight.solid.svg";
import EriaContactDevice from "../assets/img/icon.devices.square.eria_CSW_81909.solid.svg";

import GatewayOffline from "../assets/img/icon.dashboard.offline_gateway.red.svg";
import AddUnitIcon from "../assets/img/icon.dashboard.add_unit.svg";
import PopOutIcon from "../assets/img/icon.popout.16.svg";

import GoToSettingsIcon from "../assets/img/icon.goto_settings.svg";

import SystemIssuesIcon from "../assets/img/icon.notifications.system_issue_bell.svg";
import DelayedIcon from "../assets/img/icon.notifications.delayed_notification.svg";
import FallIcon from "../assets/img/icon.notification.fall_detected.svg";

const Icons = {
  WatchflagDisabled,
  WatchflagEnabled,
  PausedUnitIcon,
  BathroomAnomaly,
  HeatIndexWarning,
  HelpButton,
  ActivityEvent,
  NightEvent,
  GearIcon,
  HelpButtonOutlined,
  PausedUnitWhiteIcon,
  DoorExit,
  NoGateway,
  KitchenDashboard,
  Resolved,
  Assigned,
  DoorOpen,
  HandOff,
  Log,
  HandOffSettings,
  UnitsResidents,
  CareUser,
  CommunityUsers,
  NotificationSettings,
  NotificationGroups,
  CommunitySettings,
  OrganizationSettings,
  CloseIcon,
  ReportIcon,
  PrintReportIcon,
  AnalyticsShortcut,
  GatewayOffline,
  AddUnitIcon,
  PopOutIcon,
  FallIcon,
};

export const Navigationicons = {
  DashboardIcon,
  NotificationsIcon,
  AnalyticsIcon,
  DevicesIcon,
  SettingsIcon,
  ShopIcon,
  ReportsIconWhite,
};

export const DashboardIcons = {
  ActivityDashboard,
  BathroomDashboard,
  SleepDashboard,
  TemperatureDashboard,
  KitchenDashboard,
  GatewayOffline,
  AddUnitIcon,
};

export const ContactIcons = {
  PhonePager,
  PhoneHome,
  PhoneMain,
  Email,
  PhoneMobile,
  PhoneWork,
  AddressBook,
  GoToSettingsIcon,
};

export const AnalyticsIcons = {
  MinimizeChart,
  MaximizeChart,
  ZoomOut,
  ZoomIn,
  PanRight,
  PanLeft,
  Calendar,
  CalendarCheck,
  CalendarClose,
};

export const NotificationIcons = {
  SortArrowDown,
  Filter,
  CheckNone,
  CheckMixed,
  CheckAll,
  Selected,
  Unselected,
  SystemIssuesIcon,
  DelayedIcon,
};

export const DevicesIcons = {
  MotionDevice,
  ContactDevice,
  HelpButtonDevice,
  GatewayDevice,
  OutletDevice,
  BatteryLow,
  Refresh,
  BulbDevice,
  RadarDevice,
  SamjinMotionDevice,
  SamjinContactDevice,
  EriaMotionDevice,
  EriaContactDevice,
};

export default Icons;
